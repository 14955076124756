
.download_button{
    display: block;
    width: 100%;
    background: #e60808;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size:12px;
    line-height: 25px;

}

.canvasstyle{
height:100vh;
overflow:auto;
}


.question_container{
    margin: auto;
    overflow: auto;
    height: 80vh;
    margin-top:10%;
}

.assignment_container{
    position:relative;
    width:500px;
  }
  
  @media only screen and (max-width: 600px) {
    .assignment_container{
     
      width:330px;
    }
  }



.focused{
  background:red !important;
  font-weight: bold;
  color:white;
}


.ant-message{
  z-index: 99999 !important;
}


.testing{
  height:100vh;
  margin-bottom:10%;
}