/* Common Style */
*{
  margin:0;
  padding: 0;
}

/* **********Main Section********** */
.chapters{
text-align: center;
font-family: Lato;
font-size: 24px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1.21;
letter-spacing: normal;
color: #707070;
padding: 20px;
}


.card-style{
margin: 5px 0px;
border-radius: 5px;
border-top:0;
box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
background-color: #ffffff;
}

.cpt{
border-bottom: 5px solid #20b803;
}

.card{
  padding: 10px;
}

.card-style img{
padding-right: 10px;
padding-top:7px;
}


.card-style p{
font-family: Lato;
font-size: 18px;
font-weight: normal;
font-style: normal;
font-stretch: normal;
line-height: 1.22;
letter-spacing: normal;
/* text-align: left; */
color: #6d6d6d;
}

.card-style .card-body{
padding: 0px 20px;
}
.col-8{
  font-display: red;
  color: grey;
}
.col-8:hover{
  color:red;
}
.card-text{
  font-display: red;
}

/* Make Responsive */
@media only screen and (max-width: 576px) {
    

}

@media only screen and (max-width: 768px) {

}
