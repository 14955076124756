/* *{
    margin:0;
    padding: 0;
    font-family: Lato;
}

.license-number img{
    height: 150px;
    width: 150px;
}

.license-number p{
  font-family: Lato;
  font-size: 35px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1.05px;
  text-align: center;
  color: #e74c3c;
  margin-bottom: 30px;
}

.license-number label{
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #e74c3c;
  margin-left: 15px;
}

.license-number .form-group{
    border-radius: 5px;
  background-color: #fdf8f8;
  border:0;
}

.license-number .form-control{
  font-size: 20px;
  border:0;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #6e6e6e;
  background-color: #fdf8f8;
}


.license-number .btn-style{
    border-radius: 5px;
    background-color: #e74c3c;
    color: #ffffff;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    
.license-number p{
    font-family: Lato;
    font-size: 12px;
    margin-bottom: 30px;
  }

  .license-number img{
    height: 100px;
    width: 100px;
}


  } */


  




.test{
  position:relative;
}

   .main-content{
    width: 80%;
    border-radius: 20px;
    box-shadow: 0 5px 5px rgba(0,0,0,.4);
    display: flex;
    position:relative;
    left:10%;
    top:27%;
  }
  .company__info{
    background-color: #008080;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
  }
  .fa-android{
    font-size:3em;
  }
  @media screen and (max-width: 640px) {
    .main-content{width: 90%;}
    .company__info{
      display: none;
    }
    .login_form{
      border-top-left-radius:20px;
      border-bottom-left-radius:20px;
    }
  }
  @media screen and (min-width: 642px) and (max-width:800px){
    .main-content{width: 70%;}
  }
  .row > h2{
    color:#008080;
  }
  .login_form{
    background-color: #fff;
    border-top-right-radius:20px;
    border-bottom-right-radius:20px;
    border-top:1px solid #ccc;
    border-right:1px solid #ccc;
    border-radius:2rem;
  }
  form{
    padding: 0 2em;
  }
  .form__input{
    width: 100%;
    border:0px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid #aaa;
    padding: 1em .5em .5em;
    padding-left: 2em;
    outline:none;
    margin:1.5em auto;
    transition: all .5s ease;
  }
  .form__input:focus{
    border-bottom-color: #008080;
    box-shadow: 0 0 5px rgba(0,80,80,.4); 
    border-radius: 4px;
  }
  .btn{
    transition: all .5s ease;
    width: 40%;
    border-radius: 30px;
    color:#008080;
    font-weight: 600;
    background-color: #fff;
    border: 1px solid #008080;
    margin:0 auto;
    margin-top: 1.5em;
    margin-bottom: 1em;
  }
  .btn:hover, .btn:focus{
    background-color: #008080;
    color:#fff;
  }