/* Common Style */
*{
    margin:0;
    padding: 0;
}

/* *********Nav Bar and header ****** */
.main-navbar{
  box-shadow: 0 3px 6px 0 rgba(231, 76, 60, 0.2);
  background-color: #ffffff;
}



.dashboard-nav{
    background-color: #ffffff;
    /* border:1px salmon solid; */
}

.dashboard-nav .i-tag{
    font-size: 40px;
    color: #6d6d6d;
}

.dashboard-nav li{
    margin-left: 20px;
}


.dashboard-nav .profile{
    background: #e74c3c;
    border-radius: 50%;
    width: 50px;
    height: 50px;;
}


.nav-menu a{
  font-family: Lato;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #e74c3c;

}

/*********** drop down ********** */
.dropbtn {
    background-color: #ffffff;
    color: white;
    padding: 20px;
    font-size: 16px;
    border: 1px;
    cursor: pointer;
  }
  
  .dropbtn :hover, .dropbtn:focus {
    background-color: #3e8e41;
  }
  button#dropdownMenuButton:focus{
    outline: none;
  }
  
  .dropdown {
    float: right;
    position: relative;
    display: inline-block;
  }
  #dropdownMenuButton{
    background-color:white;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    right: 0;
    z-index: 1;
  }
  
  .dropdown-content{
    color: black;
    padding: 5px 0px;
    text-decoration: none;
    display: block;
    border-top:1px solid #ddd;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}
  
  /* ------------------ */
  .dropdown-menu a{
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #e74c3c;
  
  }

  .languageSelecter {
    margin: 0 30px;
  }

  .languageSelecter > button {
    margin: 0 5px;
    padding: 5px;
    background: none;
    font-size: 12px;
    color: #e74c3c;
    border: 1px solid #e74c3c;
    border-radius: 5px;
  }

  .languageSelecter > button:focus {
    outline: none;
  }


/*   
@media only screen and (max-width:  576px) {
.navbar-brand{

}
} */

.dropdown-menu{
display: none;
    left: -92px !important;
    min-width: 8rem !important;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    z-index: 20;
}

.imageSize{
  width:40%;
  margin-top: 17px;
}

@media only screen and (max-width:  576px) {

  .imageSize{
    width:100%;
    margin-top: 0
  } 
  
.dashboard-nav .i-tag{
  font-size: 16px;
  color: #6d6d6d;
} 
  }