a{
  text-decoration:none;
  color:black;
  text-transform: uppercase;
  text-align:center;
  cursor: pointer !important;
}
.actives{
  color:red !important;
  text-transform: none;

}
.size{
 font-size:20px;
 font-weight:bolder;

}
/* 
.customCanvas {
  height: 500px;
  max-height: 500px;
  min-width: 200px;
  max-width: 100%;
  overflow: auto;
}
@media only screen and (max-width: 600px) {
  
.customCanvas {
  height: 500px;
  max-height: 500px;
  min-width: 200px;
  max-width: 500px;
  overflow: auto;
}
} */

.test
{
  width:90%;
  margin:auto;
  overflow:auto;
  height:100vh;
  margin-top: 10%;
}


.link_style:hover{
  text-decoration: none;
}


.nav_btn {
  background-color: transparent;
  border: none;
  color: white;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
}
.chapter_container{
  position:relative;
  width:900px;
}

.navbar_pdf{
  z-index: 999;
  position: absolute;
  width:inherit;
  padding: 10px;
  overflow:auto;
  margin:auto;
  background:black;
  color:white;
}



.MuiTypography-h5{

  color:white;
}

.react-pdf__message {
  text-align: center;
  margin-top: 10%;
  font-size: 2rem;
}

@media only screen and (max-width: 600px) {
  .chapter_container{
   
    width:100%;
  }

  .mobile_container_pdf{
    padding:0;
    margin:0;
  }

  .navbar_pdf{
    z-index: 999;
    position: relative;
    width:inherit;
    padding: 10px;
    overflow:auto;
    margin:auto;
    background:black;
    color:white;
  }

  .test {
    width: 100%;
    margin: auto;
    overflow: auto;
    height: 100vh;
}

}